import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import NavHeader from '../components/products/NavHeader'
import ScViewer from '../components/products/sc/ScViewer'

const SpaghettiCheese = ({pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <Page>
      <Container>
        <NavHeader productName={'spaghetti cheese'}/>
        <ScViewer productName={'SPAGHETTI CHEESE'} />
      </Container>
    </Page>
  </IndexLayout>
)

export default SpaghettiCheese
