import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from '@emotion/styled'


const MamaMaryImg = styled(Img)`
img{
object-fit:fill !important;
}
`
function MamaMaryLight(props) {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "mamamary-light.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const {url, altText, title} = props;
  const styles = {
    width:132,
    opacity:.5,
    display:'var(--light)',
    objectFit:'fill !important'

  }
  return (
    <MamaMaryImg
      style={styles}
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt={altText}
      title={title} />
  )
}

export default MamaMaryLight
